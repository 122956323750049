import "./Styles/App.css";
import { useRef, useState } from "react";
import Menu from "./Components/Menu";
import Box from "@mui/material/Box";
import doodle from "./Assets/Open Doodles - In the Zone.svg";
import doodle1 from "./Assets/selfie.png";
import doodle2 from "./Assets/Open Doodles - Stuff To Do.svg";
import doodle3 from "./Assets/unbox.png";
import doodle4 from "./Assets/walking.png";
import projectWidi2 from "./Assets/Projects/WidiLocks/8.png";
import projectWidi from "./Assets/Projects/WidiLocks/1.png";
import projectAl from "./Assets/Projects/Ashesi As A Living Lab/1.png";
import projectJobsrus from "./Assets/Projects/JobsRUS/1.png";
import projectTap from "./Assets/Projects/TheAnloProject/Capture.JPG";
import projectTradex from "./Assets/Projects/TradEx/1.png";
import projectVotechain from "./Assets/Projects/Votechain/Picture1.png";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { Stack, TextField } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "./Styles/Swiper.css";
import emailjs from "@emailjs/browser";
import resume from "./Assets/CV_Mark_Zoiku.pdf";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import CloseIcon from "@mui/icons-material/Close";
import { Autoplay } from "swiper";

const App = () => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const formRef = useRef();
    const [error, setError] = useState(false);
    const [open, setOpen] = useState(true);
    const buttonContainerStyle = { margin: "20px 0 0 0" };
    const redirection = (path) => () => window.open(`${path}`, "_blank");

    const Button = styled(LoadingButton)({
        boxShadow: "none",
        textTransform: "none",
        lineHeight: 1.5,
        fontSize: "small",
        padding: 10,
        background: "black",
        color: "white",
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(","),
        "&:hover": {
            boxShadow: "none",
            background: "black",
            color: "white",
        },
        "&:active": {
            boxShadow: "none",
            background: "black",
            color: "white",
        },
        "&:focus": {}
    });

    const handlesubmit = (e) => {
        e.preventDefault();
        setLoading(true);
        emailjs
            .sendForm(
                "service_ddqcvcu",
                "template_fw64eqd",
                formRef.current,
                "user_mWpIoR54q6gnVw2LcX3dE"
            )
            .then(
                () => {
                    setLoading(false);
                    setError(false);
                    setSuccess(true);
                    e.target.reset();
                },
                () => {
                    setLoading(false);
                    setSuccess(false);
                    setError(true);
                }
            );
    };

    return (
        <div>
            <Menu />

            <div className="doodle doodle-1 margin-top-bottom-20 text-center">
                <img src={doodle} alt="" />
            </div>

            <div className="text-center text-weight-800 text-x-large margin-top-bottom-20">
                [ insert catchy intro ]
            </div>

            <div className="text-center padding-left-right-15">
                it's 3:11am and these doodles have inspired the redesign of my portfolio
            </div>

            <div className="margin-top-bottom-40">
                <section id="about">
                    <div className="section-content">
                        <div>
                            <div className="section-title">
                                <div>section 1/4</div>
                                <div>about me</div>
                            </div>

                            <div>
                                the name's mark zoiku, and i'm a <span className="crossed-out">developer</span> computer scientist with a niche
                                for mobile and web development :0 i build web technologies to solve problems.
                            </div>

                            <div style={buttonContainerStyle}>
                                <Stack>
                                    <Button variant="contained" onClick={redirection(resume)}>view my resume</Button>
                                </Stack>
                            </div>
                        </div>
                    </div>

                    <div className="doodle-backgrounds doodle-background-1">
                        <img src={doodle1} alt="" />
                    </div>
                </section>

                <section id="tech">
                    <div className="section-content">
                        <div>
                            <div className="section-title">
                                <div>section 2/4</div>
                                <div>technologies</div>
                            </div>

                            <div>
                                <ul className="margin-left-20 section-lists">
                                    <li>os: {"["}<span>"ubuntu", "kali"</span>{"]"}</li>
                                    <li>containerization: {"["}<span>"docker"</span>{"]"}</li>
                                    <li>databases: {"["}<span>"mongodb", "mysql", "firestore"</span>{"]"}</li>
                                    <li>languages: {"["}<span>"typescript", "java", "python"...</span>{"]"}</li>
                                    <li>frameworks: {"["}<span>"express js", "react js", "angular"</span>{"]"}</li>
                                    <li>runtime env: {"["}<span>"node js"</span>{"]"}</li>
                                </ul>
                            </div>

                            <div style={buttonContainerStyle}>
                                <Stack>
                                    <Button variant="contained" onClick={redirection("https://github.com/Zoiku")}>view my github</Button>
                                </Stack>
                            </div>
                        </div>
                    </div>

                    <div className="doodle-backgrounds doodle-background-2">
                        <img src={doodle2} alt="" />
                    </div>
                </section>

                <section id="projects">
                    <div className="section-content">
                        <div>
                            <div className="section-title">
                                <div>section 3/4</div>
                                <div>projects</div>
                            </div>

                            <div className="projects-container">
                                <Swiper autoplay={{ delay: 5000, disableOnInteraction: false }} modules={[Autoplay]} className="mySwiper">
                                    <SwiperSlide>
                                        <div className="project">
                                            <div className="project-title">widiadmin v2</div>
                                            <div className="project-description">
                                                a remodeled development of widi modern technology locks' admin web application (check project at the bottom of the page), to record and track company activities like payment history, customers, debtors and many more
                                            </div>
                                            <div className="project-image">
                                                <img src={projectWidi2} alt="" />
                                            </div>
                                            <div className="tools-used-container">
                                                <div>tools used</div>
                                                <ul className="margin-left-20">
                                                    <li>react js</li>
                                                    <li>express js</li>
                                                    <li>jwt</li>
                                                    <li>mongodb</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div style={buttonContainerStyle}>
                                            <Stack>
                                                <Button variant="contained" disabled>{"< />"}</Button>
                                            </Stack>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="project">
                                            <div className="project-title">votechain</div>
                                            <div className="project-description">
                                                votechain is a security-oriented web application powered by blockchain to provide ghanaian voters with remote election services while maximizing pre and post voter experience
                                            </div>
                                            <div className="project-image">
                                                <img src={projectVotechain} alt="" />
                                            </div>
                                            <div className="tools-used-container">
                                                <div>tools used</div>
                                                <ul className="margin-left-20">
                                                    <li>redux</li>
                                                    <li>twillio</li>
                                                    <li>express js</li>
                                                    <li>mysql</li>
                                                    <li>react js</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div style={buttonContainerStyle}>
                                            <Stack>
                                                <Button variant="contained" disabled>{"< />"}</Button>
                                            </Stack>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="project">
                                            <div className="project-title">ashesi living lab</div>
                                            <div className="project-description">
                                                this living lab project aims at equipping the ashesi campus with internet of things (iot) based technologies to provide smart capabilites to the ashesi campus for remote monitoring and control
                                            </div>
                                            <div className="project-image">
                                                <img src={projectAl} alt="" />
                                            </div>
                                            <div className="tools-used-container">
                                                <div>tools used</div>
                                                <ul className="margin-left-20">
                                                    <li>react js</li>
                                                    <li>express js</li>
                                                    <li>firebase</li>
                                                    <li>material ui</li>
                                                </ul>
                                            </div>
                                            <div style={buttonContainerStyle}>
                                                <Stack>
                                                    <Button onClick={redirection("https://ashesilivinglab.herokuapp.com/")}>visit website</Button>
                                                </Stack>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="project">
                                            <div className="project-title">anlo literacy project</div>
                                            <div className="project-description">
                                                a website for the anlo literacy project. included is a blog page where the project's progress, and other related articles are documented
                                            </div>
                                            <div className="project-image">
                                                <img src={projectTap} alt="" />
                                            </div>
                                            <div className="tools-used-container">
                                                <div>tools used</div>
                                                <ul className="margin-left-20">
                                                    <li>react js</li>
                                                    <li>google maps api</li>
                                                    <li>email js</li>
                                                </ul>
                                            </div>
                                            <div style={buttonContainerStyle}>
                                                <Stack>
                                                    <Button variant="contained" onClick={redirection("https://www.anloliteraryproject.org/")}>visit website</Button>
                                                </Stack>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="project">
                                            <div className="project-title">jobs r us</div>
                                            <div className="project-description">
                                                a cross platform mobile app that connects employer and employee. Simply create a job posting and watch interested persons interact with it, either via sms or call
                                            </div>
                                            <div className="project-image">
                                                <img src={projectJobsrus} alt="" />
                                            </div>
                                            <div className="tools-used-container">
                                                <div>tools used</div>
                                                <ul className="margin-left-20">
                                                    <li>ionic</li>
                                                    <li>angular</li>
                                                    <li>typescript</li>
                                                    <li>express js</li>
                                                    <li>firebase</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div style={buttonContainerStyle}>
                                            <Stack>
                                                <Button variant="contained" disabled>{"< />"}</Button>
                                            </Stack>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="project">
                                            <div className="project-title">tradex</div>
                                            <div className="project-description">
                                                tradex is a website designed to provide investors with investment details on the ghanaian stock exchange. users can build their very own stock index by adding and removing stocks from their index
                                            </div>
                                            <div className="project-image">
                                                <img src={projectTradex} alt="" />
                                            </div>
                                            <div className="tools-used-container">
                                                <div>tools used</div>
                                                <ul className="margin-left-20">
                                                    <li>react js</li>
                                                    <li>swiper js</li>
                                                    <li>email jobs</li>
                                                </ul>
                                            </div>
                                            <div style={buttonContainerStyle}>
                                                <Stack>
                                                    <Button variant="contained" onClick={redirection("https://tradexgh.herokuapp.com/")}>visit website</Button>
                                                </Stack>
                                            </div>
                                        </div>
                                    </SwiperSlide>

                                    <SwiperSlide>
                                        <div className="project">
                                            <div className="project-title">widi admin</div>
                                            <div className="project-description">
                                                widiLocks admin is an admin site for widi modern technology locks, ghana, to record and track company activities like payment history, customers, debtors and many more
                                            </div>
                                            <div className="project-image">
                                                <img src={projectWidi} alt="" />
                                            </div>
                                            <div className="tools-used-container">
                                                <div>tools used</div>
                                                <ul className="margin-left-20">
                                                    <li>php</li>
                                                    <li>mysql</li>
                                                    <li>javascript</li>
                                                    <li>html</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div style={buttonContainerStyle}>
                                            <Stack>
                                                <Button variant="contained" disabled>{"< />"}</Button>
                                            </Stack>
                                        </div>
                                    </SwiperSlide>
                                </Swiper>
                            </div>
                        </div>
                    </div>

                    <div className="doodle-backgrounds doodle-background-3">
                        <img src={doodle3} alt="" />
                    </div>
                </section>

                <section id="contact">
                    <div className="section-content">
                        <div>
                            <div className="section-title">
                                <div>section 4/4</div>
                                <div>contact me</div>
                            </div>

                            <div>
                                <Box
                                    sx={{
                                        '& > :not(style)': { marginBottom: 1, width: '100%' },
                                    }}
                                    autoComplete="off"
                                    component={"form"}
                                    onSubmit={handlesubmit}
                                    ref={formRef}
                                >
                                    {success && (
                                        <Collapse in={open}>
                                            <Alert
                                                variant="standard"
                                                severity="success"
                                                action={
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => {
                                                            setOpen(false);
                                                        }}
                                                    >
                                                        {" "}
                                                        <CloseIcon fontSize="inherit" />
                                                    </IconButton>
                                                }
                                            >
                                                {" "}
                                                <AlertTitle>Success</AlertTitle>Thank you for your message
                                            </Alert>
                                        </Collapse>
                                    )}

                                    {
                                        error && (<Collapse in={open}>
                                            <Alert
                                                variant="standard"
                                                severity="error"
                                                action={
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => {
                                                            setOpen(false);
                                                        }}
                                                    >
                                                        {" "}
                                                        <CloseIcon fontSize="inherit" />
                                                    </IconButton>
                                                }
                                            >
                                                {" "}
                                                <AlertTitle>Error</AlertTitle>Uh oh, something went wrong
                                            </Alert>
                                        </Collapse>)
                                    }

                                    <div className="form-group">
                                        <TextField required fullWidth name="fullname" label="full name" variant="outlined" />
                                        <TextField required fullWidth name="email" label="email" type="email" variant="outlined" />
                                    </div>

                                    <div>
                                        <TextField required fullWidth name="subject" label="subject" variant="outlined" />
                                    </div>

                                    <div>
                                        <TextField required multiline rows={2} fullWidth name="message" label="message" variant="outlined" />
                                    </div>

                                    <Button variant="contained" loading={loading} type="submit">send message</Button>
                                </Box>
                            </div>
                        </div>
                    </div>

                    <div className="doodle-backgrounds doodle-background-4">
                        <img src={doodle4} alt="" />
                    </div>
                </section>
            </div>

            <footer>
                <div>
                    <span>&#169; Copyright {new Date().getFullYear()}</span>. All rights reserved.
                </div>
            </footer>
        </div>
    )
}

export default App;