import "../Styles/Menu.css";
import { useState } from "react";
import { stack as Hamburger } from "react-burger-menu";
import doodleMenu from "../Assets/Open Doodles - Swift.svg";

const Menu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const redirection = (path) => () => {
        window.open(`${path}`, "_self");
        setIsOpen(false);
    }

    const handleStateChange = state => setIsOpen(state.isOpen);

    return (
        <div className="menu-container">
            <div className="logo-name">{"{ developer: "} <span className="text-bold text-color-special">"mark zoiku"</span> {" }"}</div>

            <Hamburger onStateChange={handleStateChange} isOpen={isOpen} width={"100%"}>
                <div onClick={redirection("#about")} className="menu-links">about</div>
                <div onClick={redirection("#tech")} className="menu-links">technologies</div>
                <div onClick={redirection("#projects")} className="menu-links">projects</div>
                <div onClick={redirection("#contact")} className="menu-links">contact</div>
                <div className="menu-doodle">
                    <img src={doodleMenu} alt="" />
                </div>
            </Hamburger>
        </div>
    )
}

export default Menu;